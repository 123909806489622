import React from 'react';
import { Typography, Card, CardContent } from '@mui/material';

const ArticleList = ({ articles }) => {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Articles
      </Typography>
      {articles.map(article => (
        <Card key={article.id} sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h5">{article.title}</Typography>
            <Typography variant="body2">{article.content}</Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ArticleList;