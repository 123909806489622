import React, { useState } from 'react';
import { 
  List, 
  ListItemText,
  ListItemButton,
  Toolbar, 
  Divider, 
  Collapse,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { slugify } from './utils/stringUtils';

const ArticleDrawer = ({ categories }) => {
  const [openCategories, setOpenCategories] = useState({});
  const theme = useTheme();

  const handleClick = (category) => {
    setOpenCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };


  return (
    <div>
      <Toolbar />
      <Divider />
      {Object.entries(categories).map(([category, articles]) => (
        <React.Fragment key={category}>
          <List>
            <ListItemButton 
              onClick={() => handleClick(category)}
              sx={{
                backgroundColor: openCategories[category] 
                  ? theme.palette.action.selected 
                  : 'inherit',
              }}
            >
              <ListItemText primary={category} />
              {openCategories[category] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openCategories[category]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {articles.map((article) => (
                  <ListItemButton 
                    key={article.id} 
                    component={Link} 
                    to={`/article/${category}/${slugify(article.title)}`} 
                    sx={{ pl: 4 }}
                  >
                    <ListItemText primary={article.title} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </List>
          <Divider />
        </React.Fragment>
      ))}
    </div>
  );
};

export default ArticleDrawer;