import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  TextField, 
  Button, 
  Typography, 
  Box, 
  List, 
  ListItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const EditArticle = ({ articleId, onSave }) => {
  const [article, setArticle] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentParagraph, setCurrentParagraph] = useState({ header: '', body: '' });
  const [editIndex, setEditIndex] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://nightcore.cl/api/articles/${articleId}`);
        setArticle(response.data);
      } catch (error) {
        console.error('Error fetching article:', error);
        setSnackbar({ open: true, message: 'Error fetching article' });
      } finally {
        setLoading(false);
      }
    };
    fetchArticle();
  }, [articleId]);

  const handleSave = async () => {
    try {
      setSaving(true);
      await axios.put(`https://nightcore.cl/api/articles/${articleId}`, article);
      setSnackbar({ open: true, message: 'Article saved successfully' });
      if (onSave) onSave();
    } catch (error) {
      console.error('Error saving article:', error);
      setSnackbar({ open: true, message: 'Error saving article' });
    } finally {
      setSaving(false);
    }
  };

  const handleAddParagraph = () => {
    setCurrentParagraph({ header: '', body: '' });
    setEditIndex(-1);
    setOpenDialog(true);
  };

  const handleEditParagraph = (index) => {
    setCurrentParagraph(article.paragraphs[index]);
    setEditIndex(index);
    setOpenDialog(true);
  };

  const handleDeleteParagraph = async (index) => {
    const updatedParagraphs = [...article.paragraphs];
    updatedParagraphs.splice(index, 1);
    setArticle({ ...article, paragraphs: updatedParagraphs });

    if (article.paragraphs[index].id) {
      try {
        await axios.delete(`https://nightcore.cl/api/articles/${articleId}/paragraphs/${article.paragraphs[index].id}`);
      } catch (error) {
        console.error('Error deleting paragraph:', error);
        setSnackbar({ open: true, message: 'Error deleting paragraph' });
      }
    }
  };

  const handleDialogSave = () => {
    const updatedParagraphs = [...article.paragraphs];
    if (editIndex === -1) {
      updatedParagraphs.push(currentParagraph);
    } else {
      updatedParagraphs[editIndex] = currentParagraph;
    }
    setArticle({ ...article, paragraphs: updatedParagraphs });
    setOpenDialog(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!article) {
    return <Typography>Article not found</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Edit Article
      </Typography>
      <TextField
        fullWidth
        label="Title"
        value={article.title}
        onChange={(e) => setArticle({ ...article, title: e.target.value })}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Category"
        value={article.category}
        onChange={(e) => setArticle({ ...article, category: e.target.value })}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Content"
        value={article.content}
        onChange={(e) => setArticle({ ...article, content: e.target.value })}
        margin="normal"
        multiline
        rows={4}
      />
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Paragraphs
      </Typography>
      <List>
        {article.paragraphs && article.paragraphs.map((paragraph, index) => (
          <ListItem key={index} sx={{ border: '1px solid #ddd', mb: 1, borderRadius: 1 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">{paragraph.header}</Typography>
              <Typography variant="body2">{paragraph.body}</Typography>
            </Box>
            <IconButton onClick={() => handleEditParagraph(index)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteParagraph(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Button startIcon={<AddIcon />} onClick={handleAddParagraph}>
        Add Paragraph
      </Button>
      <Button 
        variant="contained" 
        onClick={handleSave} 
        sx={{ mt: 2 }}
        disabled={saving}
      >
        {saving ? 'Saving...' : 'Save Article'}
      </Button>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{editIndex === -1 ? 'Add Paragraph' : 'Edit Paragraph'}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Header"
            value={currentParagraph.header}
            onChange={(e) => setCurrentParagraph({ ...currentParagraph, header: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Body"
            value={currentParagraph.body}
            onChange={(e) => setCurrentParagraph({ ...currentParagraph, body: e.target.value })}
            margin="normal"
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDialogSave}>Save</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />
    </Box>
  );
};

export default EditArticle;