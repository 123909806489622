import React, { useState } from 'react';
import axios from 'axios';
import { 
  TextField, 
  Button, 
  Typography, 
  Box, 
  Select, 
  MenuItem, 
  InputLabel, 
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const NewArticle = ({ categories, onNewCategory }) => {
  const [title, setTitle] = useState('');
  const [categoryChoice, setCategoryChoice] = useState('existing');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [paragraphs, setParagraphs] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentParagraph, setCurrentParagraph] = useState({ header: '', body: '' });
  const [editIndex, setEditIndex] = useState(-1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const category = categoryChoice === 'existing' ? selectedCategory : newCategory;
    try {
      await axios.post('https://nightcore.cl/api/articles', { title, category, paragraphs });
      if (categoryChoice === 'new' && onNewCategory) {
        onNewCategory(newCategory);
      }
      setTitle('');
      setSelectedCategory('');
      setNewCategory('');
      setCategoryChoice('existing');
      setParagraphs([]);
      alert('Article created successfully!');
    } catch (error) {
      console.error('Error creating article:', error);
      alert('Error creating article');
    }
  };

  const handleAddParagraph = () => {
    setCurrentParagraph({ header: '', body: '' });
    setEditIndex(-1);
    setOpenDialog(true);
  };

  const handleEditParagraph = (index) => {
    setCurrentParagraph(paragraphs[index]);
    setEditIndex(index);
    setOpenDialog(true);
  };

  const handleDeleteParagraph = (index) => {
    const updatedParagraphs = [...paragraphs];
    updatedParagraphs.splice(index, 1);
    setParagraphs(updatedParagraphs);
  };

  const handleDialogSave = () => {
    const updatedParagraphs = [...paragraphs];
    if (editIndex === -1) {
      updatedParagraphs.push(currentParagraph);
    } else {
      updatedParagraphs[editIndex] = currentParagraph;
    }
    setParagraphs(updatedParagraphs);
    setOpenDialog(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 600 }}>
      <Typography variant="h4" gutterBottom>
        Create New Article
      </Typography>
      <TextField
        fullWidth
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
        margin="normal"
      />
      <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">Category</FormLabel>
        <RadioGroup
          aria-label="category-choice"
          name="category-choice"
          value={categoryChoice}
          onChange={(e) => setCategoryChoice(e.target.value)}
        >
          <FormControlLabel value="existing" control={<Radio />} label="Choose existing category" />
          <FormControlLabel value="new" control={<Radio />} label="Create new category" />
        </RadioGroup>
      </FormControl>
      {categoryChoice === 'existing' ? (
        <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            value={selectedCategory}
            label="Category"
            onChange={(e) => setSelectedCategory(e.target.value)}
            required={categoryChoice === 'existing'}
          >
            {categories.map((cat) => (
              <MenuItem key={cat} value={cat}>{cat}</MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          fullWidth
          label="New Category Name"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          required={categoryChoice === 'new'}
          margin="normal"
        />
      )}
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Paragraphs
      </Typography>
      <List>
        {paragraphs.map((paragraph, index) => (
          <ListItem key={index} sx={{ border: '1px solid #ddd', mb: 1, borderRadius: 1 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">{paragraph.header}</Typography>
              <Typography variant="body2">{paragraph.body}</Typography>
            </Box>
            <IconButton onClick={() => handleEditParagraph(index)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteParagraph(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Button startIcon={<AddIcon />} onClick={handleAddParagraph}>
        Add Paragraph
      </Button>
      <Button variant="contained" type="submit" sx={{ mt: 2 }}>
        Create Article
      </Button>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{editIndex === -1 ? 'Add Paragraph' : 'Edit Paragraph'}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Header"
            value={currentParagraph.header}
            onChange={(e) => setCurrentParagraph({ ...currentParagraph, header: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Body"
            value={currentParagraph.body}
            onChange={(e) => setCurrentParagraph({ ...currentParagraph, body: e.target.value })}
            margin="normal"
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDialogSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NewArticle;