import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, Button, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { slugify } from './utils/stringUtils';
import EditArticle from './EditArticle';
import axios from 'axios';

// Styled component para el texto con saltos de línea
const FormattedTypography = styled(Typography)({
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
});

const PluginPage = () => {
  const { category, titleSlug } = useParams();
  const [article, setArticle] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`https://nightcore.cl/api/articles/category/${category}`);
        const foundArticle = response.data.find(a => slugify(a.title) === titleSlug);
        setArticle(foundArticle);
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };
    fetchArticle();
  }, [category, titleSlug]);

  if (!article) {
    return <Typography>Article not found</Typography>;
  }

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    setIsEditing(false);
    // Optionally, you can refresh the article data here
  };

  return (
    <Box sx={{ maxWidth: '1800px', margin: '20px', alignItems: 'center', padding: '20px' }}>
      {isEditing ? (
        <EditArticle articleId={article.id} onSave={handleSave} />
      ) : (
        <>
          <Typography variant="h4" gutterBottom>
            {article.title}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Categoría: {category}
          </Typography>
          <Paper elevation={3} sx={{ padding: '20px', marginBottom: '20px' }}>
            <FormattedTypography variant="body1" paragraph>
              {article.content}
            </FormattedTypography>
          </Paper>
          <Grid container spacing={3}>
            {article.paragraphs.map((paragraph, index) => (
              <Grid item xs={12} md={12} key={index}>
                <Paper variant="outlined">
                  <Typography margin="10px"variant="h4" gutterBottom>{paragraph.header}</Typography>
                </Paper>
                <Paper  elevation={1} sx={{ padding: '20px', height: '100%' }}>
                  
                  <FormattedTypography variant="body1">
                    {paragraph.body}
                  </FormattedTypography>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ marginTop: '20px' }}>
            <Button variant="contained" onClick={handleEditToggle}>
              Edit Article
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PluginPage;