import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Box, CssBaseline, Drawer, AppBar, Toolbar, IconButton, Button, ThemeProvider, createTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ArticleList from './components/ArticleList';
import NewArticle from './components/NewArticle';
import ArticleDrawer from './components/ArticleDrawer';
import PluginPage from './components/PluginPage';
import axios from 'axios';
import './App.css';
import nightcoreLogo from './images/logo.png';

const drawerWidth = 240;

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [categories, setCategories] = useState({});
  const [darkMode, setDarkMode] = useState(true);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://nightcore.cl/api/articles');
        const articles = response.data;
        
        const groupedArticles = articles.reduce((acc, article) => {
          if (!acc[article.category]) {
            acc[article.category] = [];
          }
          acc[article.category].push(article);
          return acc;
        }, {});

        setCategories(groupedArticles);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode');
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Box component={Link} to="/" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <img src={nightcoreLogo} alt="Nightcore Logo" style={{ height: '40px' }} />
              </Box>
              <IconButton sx={{ ml: 1 }} onClick={toggleDarkMode} color="inherit">
                {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
              <Button 
                component={Link} 
                to="/new-article" 
                variant="contained"
                sx={{ ml: 2 }}
              >
                New Article
              </Button>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              <ArticleDrawer categories={categories} />
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
              open
            >
              <ArticleDrawer categories={categories} />
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
          >
            <Toolbar />
            <Routes>
              <Route path="/" element={<ArticleList articles={Object.values(categories).flat()} />} />
              <Route path="/new-article" element={<NewArticle categories={Object.keys(categories)} />} />
              <Route path="/article/:category/:titleSlug" element={<PluginPage categories={categories} />} />
            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;